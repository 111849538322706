<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard>
          <CForm @submit.prevent="submit">
            <CCardHeader>
              <strong>Nuevo curso</strong>
            </CCardHeader>
            <CCardBody>
              <CInput
                placeholder="Introduce el título del curso"
                v-model="form.title"
                label="Título del curso"
                required
              />
              <CTextarea
                label="Descripción del curso"
                placeholder=""
                vertical
                rows="3"
                v-model="form.description"
                required
              />
              <CFormLabel for="formFile">Imagen del curso</CFormLabel> <br>
              <input 
                type="file" 
                @change="onFileUpload" 
                required
              />
            </CCardBody>
            <CCardFooter>
              <CButton color="danger" @click="() => $router.push('/course')">
                    Regresar
                </CButton>
                <CButton class="float-right" color="primary" type="submit">
                    Guardar <CSpinner v-if="loading" variant="grow" size="sm"/>
                </CButton>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CreateCourse',
  data: function () {
    return {
      form: {
        title: '',
        description: '',
        picture: null
      },
      show: true,
      isCollapsed: true,
      loading: false,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  methods: {
    onFileUpload(event) {
        this.form.picture = event.target.files[0]
    },
    submit() {
      this.loading = true
      const token = this.$cookies.get('login_token')
      const formData = new FormData();
      formData.append('picture', this.form.picture, this.form.picture.name)
      formData.append('title', this.form.title)
      formData.append('description', this.form.description)
      axios
        .post(process.env.VUE_APP_BACKEND_URL+'/api/course', formData, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.$router.push('/course/' + response.data.course.id + '/lessons/create')
        })
    }
  }
}
</script>
